import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import { KeyValueFixedSelectableInput } from 'ecto-common/lib/KeyValueInput/KeyValueFixedSelectableInput';
import _ from 'lodash';
import Icons from 'ecto-common/lib/Icons/Icons';
import styles from './ModelEditorSignal.module.css';
import SelectSignalsDialog from 'ecto-common/lib/SelectSignalsDialog/SelectSignalsDialog';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import APIGen from 'ecto-common/lib/API/APIGen';
import { ChartSignal } from 'ecto-common/lib/SignalSelector/ChartUtils';
import UUID from 'uuidjs';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import {
  ModelDefinitionInternal,
  ModelDynamicStringProperty
} from 'ecto-common/lib/ModelForm/ModelPropType';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import T from 'ecto-common/lib/lang/Language';

export type SignalListModelDefinition<
  ObjectType extends object,
  EnvironmentType extends object = object
> = {
  modelType: typeof ModelType.SIGNAL_LIST;
  nodeId?: ModelDynamicStringProperty<ObjectType, EnvironmentType, string[]>;
  isClearable?: boolean;
  validSignalTypeIds?: string[];
  selectFromCurrentNodeOnly?: boolean;
} & ModelDefinitionInternal<ObjectType, EnvironmentType, string[]>;

type ModelEditorSignalListProps = Omit<ModelEditorProps, 'rawValue'> & {
  model: SignalListModelDefinition<object, object>;
  rawValue: string[];
  nodeId?: string;
};

const ModelEditorSignalList = ({
  model,
  helpText,
  disabled,
  rawValue,
  hasError,
  updateItem,
  nodeId: externalNodeId
}: ModelEditorSignalListProps) => {
  const [dialogIsOpen, showDialog, hideDialog] = useSimpleDialogState();
  const commonNodeId = useCommonSelector((state) => state.general.nodeId);
  const nodeId = externalNodeId || commonNodeId;

  const [signalCache, setSignalCache] = useState<Record<string, ChartSignal>>(
    {}
  );
  const signalDataQueryEnabled =
    rawValue != null &&
    rawValue.some((signalId) => signalCache[signalId] == null);

  const getSignalDataQuery = APIGen.Signals.getProvidersBySignalIds.useQuery(
    {
      signalIds: rawValue
    },
    {
      enabled: signalDataQueryEnabled
    }
  );

  useEffect(() => {
    if (getSignalDataQuery.data != null && signalDataQueryEnabled) {
      const allChartSignals: ChartSignal[] = [];

      for (const provider of getSignalDataQuery.data) {
        for (const signalId of rawValue) {
          const signal = provider.signals.find(
            (otherSignal) => otherSignal.signalId === signalId
          );

          if (signal != null) {
            const chartSignal: ChartSignal = {
              item: signal,
              chartSignalId: UUID.generate(),
              group: _.omit(provider, 'signals'),
              parent: null
            };

            allChartSignals.push(chartSignal);
          }
        }
      }

      setSignalCache((oldCache) => {
        const newCache = { ...oldCache };
        allChartSignals.forEach((chartSignal) => {
          newCache[chartSignal.item.signalId] = chartSignal;
        });
        return newCache;
      });
    }
  }, [getSignalDataQuery.data, rawValue, signalDataQueryEnabled]);

  const valueLabel = T.format(
    T.modeleditor.signalsformat,
    rawValue?.length ?? 0
  );

  const content = rawValue && rawValue.length > 0 && (
    <div className={styles.valueContainer}>
      <Icons.Signal /> <div>{valueLabel}</div>
    </div>
  );

  const updateValue = useCallback(
    (selectedSignals: ChartSignal[]) => {
      const signalIds = selectedSignals.map((signal) => signal.item.signalId);
      updateItem(signalIds);

      setSignalCache((oldCache) => ({
        ...oldCache,
        ..._.keyBy(selectedSignals, (signal) => signal.item.signalId)
      }));

      hideDialog();
    },
    [hideDialog, updateItem]
  );

  const chartSignals = useMemo(
    () => _.compact(rawValue?.map((signalId) => signalCache[signalId])),
    [rawValue, signalCache]
  );

  return (
    <>
      <KeyValueFixedSelectableInput
        disabled={disabled}
        keyText={model.label}
        value={content}
        placeholder={T.modeleditor.nosignals}
        onClick={showDialog}
        hasError={hasError}
        helpText={helpText}
        isLoading={getSignalDataQuery.isLoading}
        isDisabled={getSignalDataQuery.isLoading}
        isClearable={model.isClearable}
        onClear={() => updateItem(null)}
      />
      <SelectSignalsDialog
        isOpen={dialogIsOpen}
        nodeId={nodeId}
        onModalClose={hideDialog}
        onSignalsSelected={updateValue}
        validSignalTypeIds={model.validSignalTypeIds}
        selectedSignals={chartSignals}
        selectFromCurrentNodeOnly={model.selectFromCurrentNodeOnly}
      />
    </>
  );
};

export default React.memo(ModelEditorSignalList);
