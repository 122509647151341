/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import { CancelledErrorName } from 'ecto-common/lib/constants';
import { CommonDispatch } from 'ecto-common/lib/reducers/storeCommon';

export const REQ_STATE_DEFAULT = 'REQ_STATE_DEFAULT';
export const REQ_STATE_PENDING = 'REQ_STATE_PENDING';
export const REQ_STATE_ERROR = 'REQ_STATE_ERROR';
export const REQ_STATE_CANCELLED = 'REQ_STATE_CANCELLED';
export const REQ_STATE_SUCCESS = 'REQ_STATE_SUCCESS';

export const ALL_REQ_STATES = [
  REQ_STATE_DEFAULT,
  REQ_STATE_PENDING,
  REQ_STATE_ERROR,
  REQ_STATE_CANCELLED,
  REQ_STATE_SUCCESS
];

export const initialReqStateWithId = {};

export type RequestStatusRawProp<T> = {
  state: string;
  payload: T;
  error: any;
  blocking: boolean;
  statusText: string;
  params: any;
};

export function getInitialReqState<T>(): RequestStatusRawProp<T> {
  return {
    state: REQ_STATE_DEFAULT,
    payload: null,
    error: null,
    blocking: false,
    statusText: null,
    params: null
  };
}

export const initialReqState: RequestStatusRawProp<any> = {
  state: REQ_STATE_DEFAULT,
  payload: null,
  error: null,
  blocking: false,
  statusText: null,
  params: null
};

export type RequestStatusProp<T> = {
  isLoading: boolean;
  hasError: boolean;
  isSuccessful: boolean;
  cancelled: boolean;
  data: T;
  error: Error;
  params: any;
};

export function mapReqStateToProp<T>(reqState: any): RequestStatusProp<T> {
  return {
    isLoading: reqState.state === REQ_STATE_PENDING,
    hasError: reqState.state === REQ_STATE_ERROR,
    isSuccessful: reqState.state === REQ_STATE_SUCCESS,
    cancelled: reqState.state === REQ_STATE_CANCELLED,
    data: reqState.payload,
    error: reqState.error,
    params: reqState.params
  };
}

export const createInitialReqStates = (requests: any) => {
  return _.reduce(
    requests,
    (dict, value) => ({ ...dict, [value]: initialReqState }),
    {}
  );
};

export const createDefaultRequestReducers = (requests: any) => {
  return _.reduce(
    requests,
    (dict, value, key) => ({ ...dict, [key]: updateNamedReqState(value) }),
    {}
  );
};

export const updateNamedReqState = (name: string) => {
  return (state: any, payload: any) => {
    return { ...state, [name]: updateReqState(state[name], payload) };
  };
};

export const updateReqState = (state = initialReqState, payload: any) => {
  let data = payload.payload ? _.cloneDeep(payload.payload) : null;
  let error = null;

  if (payload.state === REQ_STATE_ERROR && payload.payload) {
    const errorPayload = payload.payload;
    error = {
      error: errorPayload.error,
      status: errorPayload.response ? errorPayload.response.status : null
    };
    data = null;
  }

  const blocking =
    payload.blocking === undefined ? state.blocking : payload.blocking;
  const statusText =
    payload.statusText === undefined ? state.statusText : payload.statusText;

  return {
    ...state,
    state: payload.state,
    payload: data,
    id: payload.id,
    params: payload.params,
    error,
    blocking,
    statusText
  };
};

export const handleRequestError = (
  reqStateConstant: string,
  error: any,
  id: string,
  dispatch: CommonDispatch,
  failText: string,
  params: any
) => {
  if (error.name === CancelledErrorName) {
    return dispatch({
      type: reqStateConstant,
      payload: {
        state: REQ_STATE_CANCELLED,
        id: id,
        payload: null,
        blocking: false,
        statusText: failText,
        params
      }
    });
  } else if (error.response) {
    try {
      if (error.response.headers.get('Content-Type') === 'application/json') {
        return error.response.json().then((jsonResponse: any) => {
          dispatch({
            type: reqStateConstant,
            payload: {
              state: REQ_STATE_ERROR,
              id: id,
              payload: { error: jsonResponse, response: error.response },
              blocking: false,
              params
            }
          });
        });
      }

      return error.response.text().then((textResponse: any) => {
        dispatch({
          type: reqStateConstant,
          payload: {
            state: REQ_STATE_ERROR,
            id: id,
            payload: { error: textResponse, response: error.response },
            blocking: false,
            params
          }
        });
      });
    } catch {
      return dispatch({
        type: reqStateConstant,
        payload: {
          state: REQ_STATE_ERROR,
          id: id,
          payload: { error: {}, wasCancelled: false, response: error.response },
          blocking: false,
          params,
          statusText: failText
        }
      });
    }
  } else {
    return dispatch({
      type: reqStateConstant,
      payload: {
        state: REQ_STATE_ERROR,
        id: id,
        payload: { error: {}, wasCancelled: false, response: error.response },
        blocking: false,
        params,
        statusText: failText
      }
    });
  }
};

export function requestIsLoading<T>(request: RequestStatusRawProp<T>) {
  return request.state === REQ_STATE_PENDING;
}
