import APIGen, {
  FullSignalProviderResponseModel
} from 'ecto-common/lib/API/APIGen';
import useLatestSignalValues from 'ecto-common/lib/hooks/useLatestSignalValues';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import {
  ProcessMapRuleTypes,
  ProcessMapSignalRuleFunctions,
  SignalDialogProcessMapAction,
  SignalDialogRule
} from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import SignalsTable, {
  SignalsTableSignal,
  SignalTableColumnsKey
} from 'ecto-common/lib/SignalsTable/SignalsTable';
import { getLatestDataPoint } from 'ecto-common/lib/SignalsTable/signalsTableUtils';
import { SignalProviderSignalWithProviderResponseModel } from 'ecto-common/lib/types/EctoCommonTypes';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';

const columnKeys = [
  SignalTableColumnsKey.NAME,
  SignalTableColumnsKey.DESCRIPTION,
  SignalTableColumnsKey.SIGNAL_PROVIDER_NAME,
  SignalTableColumnsKey.UNIT
];

type SignalVisibilityResult = {
  disabledSignalIds: string[];
  hiddenSignalIds: string[];
};

function evaluateCondition(rule: SignalDialogRule, value: number): boolean {
  if (value == null) {
    return false;
  }

  switch (rule.operator) {
    case ProcessMapRuleTypes.GreaterThan:
      return value > rule.expectedValue;
    case ProcessMapRuleTypes.LessThan:
      return value < rule.expectedValue;
    case ProcessMapRuleTypes.LessThanOrEqual:
      return value <= rule.expectedValue;
    case ProcessMapRuleTypes.GreaterThanOrEqual:
      return value >= rule.expectedValue;
    case ProcessMapRuleTypes.Equal:
      return value === rule.expectedValue;
    case ProcessMapRuleTypes.NotEqual:
      return value !== rule.expectedValue;
    default:
      return false;
  }
}

const ProcessMapSignalDialog = ({
  action,
  signalProviders,
  onModalClose
}: {
  signalProviders: FullSignalProviderResponseModel[];
  action: SignalDialogProcessMapAction;
  onModalClose: () => void;
}) => {
  const [signalIds, signalTypeToIdMapping] = useMemo(() => {
    let signalIdsToUse: string[] = [];
    const signalTypeToIdMappingToUse: Record<string, string> = {};

    if (action?.signalIds?.length > 0) {
      signalIdsToUse = [...action.signalIds];
    }

    if (action?.signalTypeIds?.length > 0) {
      const matched: Record<string, boolean> = {};

      for (const provider of signalProviders ?? []) {
        for (const signal of provider.signals) {
          if (action.signalTypeIds.includes(signal.signalTypeId)) {
            matched[signal.signalId] = true;
            signalTypeToIdMappingToUse[signal.signalTypeId] = signal.signalId;
          }
        }
      }

      for (const signalTypeId of action.signalTypeIds) {
        const entry = signalTypeToIdMappingToUse[signalTypeId];
        if (entry != null) {
          signalIdsToUse.push(entry);
        }
      }
    }

    return [signalIdsToUse, signalTypeToIdMappingToUse];
  }, [action?.signalIds, action?.signalTypeIds, signalProviders]);

  const signalData = useLatestSignalValues(null, signalIds, signalIds, null);

  const signalTypesMap = useCommonSelector(
    (state) => state.general.signalTypesMap
  );

  const signalQuery = APIGen.Signals.getProvidersBySignalIds.useQuery(
    {
      signalIds
    },
    {
      enabled: signalIds.length > 0
    }
  );

  const signalVisibility = useMemo(() => {
    const visibilityResult: SignalVisibilityResult = {
      disabledSignalIds: [],
      hiddenSignalIds: []
    };

    if (action?.signalRules == null) {
      return visibilityResult;
    }

    for (const signalId of signalIds) {
      const signalRule = action.signalRules.find(
        (rule) => rule.signalId === signalId
      );

      if (signalRule != null) {
        const currentValue =
          signalData[signalRule.compareSignalId]?.values[0]?.value ??
          signalData[signalTypeToIdMapping[signalRule.compareSignalTypeId]]
            ?.values[0]?.value;

        switch (signalRule.function) {
          case ProcessMapSignalRuleFunctions.Enabled:
            if (!evaluateCondition(signalRule, currentValue)) {
              visibilityResult.disabledSignalIds.push(signalId);
            }
            break;
          case ProcessMapSignalRuleFunctions.Visible:
            if (!evaluateCondition(signalRule, currentValue)) {
              visibilityResult.hiddenSignalIds.push(signalId);
            }
            break;
          default:
            break;
        }
      }
    }

    return visibilityResult;
  }, [action?.signalRules, signalData, signalIds, signalTypeToIdMapping]);

  const tableData: SignalsTableSignal[] = useMemo(() => {
    const unorderedSignals = _.flatMap(signalQuery.data, (provider) => {
      return _.map(
        provider.signals.filter((signal) =>
          signalIds.includes(signal.signalId)
        ),
        (signal) => ({
          ...signal,
          signalProvider: provider,
          rawSignal: signal
        })
      );
    });

    let orderedSignals = _.compact(
      signalIds.map((signalId) => {
        const foundSignal = unorderedSignals.find(
          (signal) => signal.signalId === signalId
        );

        return foundSignal != null
          ? {
              ...foundSignal,
              signalType: signalTypesMap[foundSignal.signalTypeId]
            }
          : null;
      })
    );

    orderedSignals = orderedSignals.filter(
      (signal) => !signalVisibility.hiddenSignalIds.includes(signal.signalId)
    );

    const tableSignals = _.map(orderedSignals, (signal) => {
      const dataPoint = getLatestDataPoint(signalData[signal.signalId]);

      return {
        ...signal,
        ...dataPoint,
        step: 0
      };
    });

    return tableSignals;
  }, [
    signalQuery.data,
    signalIds,
    signalTypesMap,
    signalVisibility.hiddenSignalIds,
    signalData
  ]);

  const [currentEditSignal, setCurrentEditSignal] =
    useState<SignalProviderSignalWithProviderResponseModel>(null);

  return (
    <ActionModal
      isOpen={action != null}
      onModalClose={onModalClose}
      title={T.processmaps.signaldialog.title}
      onConfirmClick={onModalClose}
      headerIcon={Icons.Signal}
      disableCancel
      actionText={T.common.done}
      isLoading={signalQuery.isLoading}
      wide
    >
      <div data-disableclosedropdownmenuonclick="1">
        <SignalsTable
          signals={tableData}
          isLoading={signalQuery.isLoading}
          hasError={signalQuery.isError}
          onClickRow={_.noop}
          setCurrentEditSignal={setCurrentEditSignal}
          currentEditSignal={currentEditSignal}
          signalValuesById={signalData}
          otherSignals={null}
          selectedSignalIds={null}
          columnKeys={columnKeys}
          disabledSignalIds={signalVisibility.disabledSignalIds}
        />
      </div>
    </ActionModal>
  );
};

export default React.memo(ProcessMapSignalDialog);
