// TODO: Convert to functional component and fix prop type errors
/* eslint-disable react/prop-types */

import React, { Component } from 'react';
import _ from 'lodash';

import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import DeleteButton from 'ecto-common/lib/Button/DeleteButton';
import ModalActionFooter from 'ecto-common/lib/Modal/ModalActionFooter';

import EditNotification from './EditNotification';
import { AlarmNotificationResponseModel } from 'ecto-common/lib/API/APIGen';

type EditNotificationDialogProps = {
  isOpen: boolean;
  isLoading: boolean;
  onAddInput: (notification: AlarmNotificationResponseModel) => void;
  onModalClose: () => void;
  onDelete: (notification: AlarmNotificationResponseModel) => void;
  nodeId: string;
  notification: AlarmNotificationResponseModel;
};

class EditNotificationDialog extends Component<EditNotificationDialogProps> {
  input: AlarmNotificationResponseModel = {
    id: null
  };

  componentDidUpdate(prevProps: EditNotificationDialogProps) {
    if (!_.isEqual(prevProps.notification, this.props.notification)) {
      this.input = this.props.notification;
    }
  }

  validateInput = (success: () => void) => {
    const hasInvalidNode = _.isEmpty(this.input.nodeIds);
    const noUsers = _.isEmpty(this.input.userIds);

    if (_.isEmpty(this.input.name)) {
      toastStore.addErrorToast(T.admin.notifications.error.missingname);
    } else if (hasInvalidNode) {
      toastStore.addErrorToast(T.admin.notifications.error.nonodeselected);
    } else if (noUsers) {
      toastStore.addErrorToast(T.admin.notifications.error.nousers);
    } else {
      success();
    }
  };

  render() {
    const {
      isOpen,
      onModalClose,
      onAddInput,
      onDelete,
      isLoading,
      notification,
      nodeId
    } = this.props;
    const footer = (
      <ModalActionFooter
        isLoading={isLoading}
        onModalClose={onModalClose}
        confirmText={notification ? T.common.save : T.common.add}
        onConfirm={() => this.validateInput(() => onAddInput(this.input))}
        leftSide={
          notification && (
            <DeleteButton
              disabled={isLoading}
              onClick={() => onDelete(notification)}
            >
              {T.admin.notifications.deletenotification}
            </DeleteButton>
          )
        }
      />
    );

    return (
      <ActionModal
        compact
        onModalClose={onModalClose}
        isOpen={isOpen}
        isLoading={isLoading}
        headerIcon={notification ? Icons.Edit : Icons.Add}
        title={
          notification
            ? T.admin.notifications.editnotification
            : T.admin.notifications.addnotification
        }
        onConfirmClick={() => this.validateInput(() => onAddInput(this.input))}
        footer={footer}
      >
        <EditNotification
          key={isOpen + ''}
          nodeId={nodeId}
          notification={notification}
          isLoading={isLoading}
          onUpdateNotification={(
            newNotification: AlarmNotificationResponseModel
          ) => {
            this.input = newNotification;
          }}
        />
      </ActionModal>
    );
  }
}

export default EditNotificationDialog;
