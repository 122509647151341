// TODO: Convert to functional component and fix prop type errors
/* eslint-disable react/prop-types */
import React from 'react';
import T from 'ecto-common/lib/lang/Language';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';

type ErrorBoundaryProps = {
  children?: React.ReactNode;
  showFullscreenError?: boolean;
};

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = {
    hasError: false
  };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: unknown) {
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      if (this.props.showFullscreenError) {
        return (
          <ToolbarContentPage title={T.common.error} showLocationPicker={false}>
            <ErrorNotice>{T.common.unknownerror}</ErrorNotice>
          </ToolbarContentPage>
        );
      }
      return <ErrorNotice>{T.common.error}</ErrorNotice>;
    }

    return this.props.children;
  }
}
