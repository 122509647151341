// // TODO: Convert to functional component and fix prop type errors
/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import Button from 'ecto-common/lib/Button/Button';
import styles from './EditEquipmentGroup.module.css';
import classNames from 'classnames';
import pageStyles from '../PageStyle.module.css';
import {
  EquipmentTypes,
  getDefaultEquipmentId
} from 'ecto-common/lib/utils/equipmentTypeUtils';
import ToolSelector from 'js/components/EditBuildingData/ToolSelector';
import AlarmSelector from 'js/components/EditBuildingData/AlarmSelector';
import zindex from 'ecto-common/lib/styles/variables/zindex';
import Select from 'ecto-common/lib/Select/Select';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import staticDataTableStyles from 'ecto-common/lib/StaticDataTable/StaticDataTable.module.css';
import { AlarmSignalGroupTemplateIds } from 'ecto-common/lib/utils/constants';
import {
  EquipmentTypeResponseModel,
  ToolType
} from 'ecto-common/lib/API/APIGen';
import { AdminSignalTemplates } from 'ecto-common/lib/types/EctoCommonTypes';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';

type AddEquipmentTemplateProps = {
  signalTemplates: AdminSignalTemplates;
  onAddEquipment: (
    equipmentId: string,
    alarmSignalGroupTemplateId: string,
    toolTypes: ToolType[]
  ) => void;
  equipmentTypes: EquipmentTypeResponseModel[];
  toolTemplates: ToolType[];
};

class AddEquipmentTemplate extends PureComponent<AddEquipmentTemplateProps> {
  state = {
    selectedEquipmentTypeId: null as string,
    selectedToolTypes: [] as ToolType[],
    selectedAlarmSignalGroupTemplateId: null as string
  };

  setEquipmentTypeValue = (val: GenericSelectOption<string>) => {
    let { selectedAlarmSignalGroupTemplateId } = this.state;

    if (val.value === EquipmentTypes.ENERGY_MANAGER) {
      selectedAlarmSignalGroupTemplateId =
        AlarmSignalGroupTemplateIds.ENERGY_MANAGER_ALARMS;
    } else if (
      selectedAlarmSignalGroupTemplateId ===
      AlarmSignalGroupTemplateIds.ENERGY_MANAGER_ALARMS
    ) {
      selectedAlarmSignalGroupTemplateId = null;
    }

    this.setState({
      selectedEquipmentTypeId: val.value,
      selectedAlarmSignalGroupTemplateId
    });
  };

  setAlarmTemplateValue = (e: GenericSelectOption<string>) => {
    this.setState({
      selectedAlarmSignalGroupTemplateId: e != null ? e.value : null
    });
  };

  setToolTemplateValue = (val: GenericSelectOption<string>[]) => {
    this.setState({ selectedToolTypes: val.map((x) => x.value) });
  };

  addEquipment = () => {
    const { onAddEquipment, equipmentTypes } = this.props;
    const {
      selectedEquipmentTypeId,
      selectedAlarmSignalGroupTemplateId,
      selectedToolTypes
    } = this.state;

    onAddEquipment(
      selectedEquipmentTypeId || getDefaultEquipmentId(equipmentTypes),
      selectedAlarmSignalGroupTemplateId,
      selectedToolTypes
    );
  };

  static _renderToolSelector(
    onChange: (e: GenericSelectOption<string>[]) => void,
    values: string[],
    toolTemplates: string[]
  ) {
    return (
      <ToolSelector
        menuPlacement="top"
        toolTemplates={toolTemplates}
        values={values}
        onChange={onChange}
      />
    );
  }

  static _renderAlarmSelector(
    onChange: (e: GenericSelectOption<string>) => void,
    value: string,
    signalTemplates: AdminSignalTemplates,
    equipmentTypeId: string
  ) {
    return (
      <AlarmSelector
        menuPlacement="top"
        signalTemplates={signalTemplates}
        onChange={onChange}
        value={value}
        equipmentTypeId={equipmentTypeId}
      />
    );
  }

  render() {
    const selectedEquipmentTypeId =
      this.state.selectedEquipmentTypeId ||
      getDefaultEquipmentId(this.props.equipmentTypes);
    const { signalTemplates, equipmentTypes, toolTemplates } = this.props;
    const { selectedToolTypes, selectedAlarmSignalGroupTemplateId } =
      this.state;

    const options = equipmentTypes.map((equipmentType) => ({
      value: equipmentType.equipmentTypeId,
      label: equipmentType.name
    }));

    const selectedEqOption = options.find(
      (x) => x.value === selectedEquipmentTypeId
    );

    return (
      <table
        className={classNames(
          staticDataTableStyles.staticDataTable,
          styles.addTable
        )}
      >
        <thead>
          <tr>
            <th>{T.admin.equipmentgroup.addequipment.title}</th>
            <th>{T.admin.equipmentgroup.addequipment.alarms}</th>
            <th>{T.admin.equipmentgroup.addequipment.tools}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Select
                name="form-field-name"
                value={selectedEqOption}
                onChange={this.setEquipmentTypeValue}
                placeholder={T.admin.equipmentgroup.addequipment.placeholder}
                className={pageStyles.smallSelector}
                maxMenuHeight={250}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: zindex.modalOverlayZIndex
                  })
                }}
                menuPortalTarget={document.body}
                menuPlacement="top"
                options={options}
              />
            </td>
            <td className={styles.alarmTemplateInput}>
              {AddEquipmentTemplate._renderAlarmSelector(
                this.setAlarmTemplateValue,
                selectedAlarmSignalGroupTemplateId,
                signalTemplates,
                selectedEquipmentTypeId
              )}
            </td>
            <td className={styles.alarmTemplateInput}>
              {AddEquipmentTemplate._renderToolSelector(
                this.setToolTemplateValue,
                selectedToolTypes,
                toolTemplates
              )}
            </td>
            <td className={pageStyles.minWidthColumn}>
              <Button onClick={this.addEquipment}>
                <Icons.Add />
                {T.admin.equipmentgroup.addequipment.add}
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default AddEquipmentTemplate;
