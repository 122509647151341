import React, { useCallback } from 'react';
import _ from 'lodash';
import Icons from 'ecto-common/lib/Icons/Icons';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import KeyValueInternalSelectableInput from 'ecto-common/lib/KeyValueInput/Internal/KeyValueInternalSelectableInput';
import SelectSignalTypeDialog from 'ecto-common/lib/SignalTypePicker/SelectSignalTypeDialog';
import T from 'ecto-common/lib/lang/Language';
import { getSignalTypeNameWithUnitFromMap } from 'ecto-common/lib/SignalSelector/SignalUtils';

import styles from './SignalTypePicker.module.css';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';

const components = {
  Menu: () => {
    return <></>;
  }
};

interface SignalTypePickerProps {
  onChange?(signalTypeIds: string[]): void;
  value?: string[];
  hasError?: boolean;
  disabled?: boolean;
  selectFolder?: boolean;
  selectOnlyFolders?: boolean;
  showIcon?: boolean;
  nodeId?: string;
  isClearable?: boolean;
  multiSelect?: boolean;
}

const SignalTypePickerMulti = ({
  onChange,
  value,
  hasError,
  disabled,
  selectFolder,
  selectOnlyFolders,
  nodeId = null,
  showIcon = true,
  isClearable = false,
  multiSelect = true
}: SignalTypePickerProps) => {
  const nodeIdFromRedux = useCommonSelector((state) => state.general.nodeId);
  const signalTypesMap = useCommonSelector(
    (state) => state.general.signalTypesMap
  );
  const signalTypeFoldersMap = useCommonSelector(
    (state) => state.general.signalTypeFoldersMap
  );
  const signalUnitTypesMap = useCommonSelector(
    (state) => state.general.signalUnitTypesMap
  );
  const [dialogIsOpen, showDialog, hideDialog] = useSimpleDialogState();

  let formattedTypeName;

  if (selectFolder) {
    if (value?.length === 1) {
      const signalFolder = signalTypeFoldersMap[value[0]];
      formattedTypeName = signalFolder?.name;
    } else {
      formattedTypeName = T.format(
        T.signaltypes.folderselectedformat,
        value?.length
      );
    }
  } else {
    if (value?.length === 1) {
      formattedTypeName = getSignalTypeNameWithUnitFromMap(
        value[0],
        signalTypesMap,
        signalUnitTypesMap
      );
    } else {
      formattedTypeName = T.format(
        T.signaltypes.typeselectedformat,
        value?.length
      );
    }
  }

  const icon = selectFolder ? <Icons.Folder /> : <Icons.Signal />;

  const options =
    value == null
      ? []
      : [
          {
            value: formattedTypeName,
            label: (
              <>
                {' '}
                {showIcon && icon} {formattedTypeName}
              </>
            )
          }
        ];

  const placeholder = selectOnlyFolders
    ? T.signaltypes.selectfolder
    : T.signaltypes.selecttype;

  const onChangeSelect = useCallback(
    (newValue: unknown) => {
      if (newValue === null) {
        onChange(null);
      }
    },
    [onChange]
  );

  return (
    <>
      <KeyValueInternalSelectableInput
        options={options}
        hasError={hasError}
        isSearchable={false}
        onChange={onChangeSelect}
        onMenuOpen={showDialog}
        value={_.head(options)}
        components={components}
        disabled={disabled}
        placeholder={placeholder}
        className={styles.input}
        isClearable={isClearable}
      />
      <SelectSignalTypeDialog
        isOpen={dialogIsOpen}
        signalTypeIds={value}
        onChangeSignalTypeIds={onChange}
        onModalClose={hideDialog}
        selectFolder={selectFolder}
        selectOnlyFolders={selectOnlyFolders}
        nodeId={nodeId ?? nodeIdFromRedux}
        multiSelect={multiSelect}
      />
    </>
  );
};

export default React.memo(SignalTypePickerMulti);
