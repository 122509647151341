import React from 'react';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import {
  ModelDefinitionInternal,
  ModelDynamicStringProperty
} from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import SignalTypePickerMulti from 'ecto-common/lib/SignalTypePicker/SignalTypePickerMulti';

export type SignalTypeListDefinition<
  ObjectType extends object,
  EnvironmentType extends object = object
> = {
  modelType: typeof ModelType.SIGNAL_TYPE_LIST;
  selectFolder?: boolean;
  nodeId?: ModelDynamicStringProperty<ObjectType, EnvironmentType, string[]>;
  isClearable?: boolean;
} & ModelDefinitionInternal<ObjectType, EnvironmentType, string[]>;

type ModelEditorSignalTypeListProps = ModelEditorProps & {
  model: SignalTypeListDefinition<object, object>;
  nodeId: string;
};

const ModelEditorSignalTypeList = ({
  model,
  disabled,
  rawValue,
  hasError,
  updateItem,
  nodeId: externalNodeId,
  helpText
}: ModelEditorSignalTypeListProps) => {
  const commonNodeId = useCommonSelector((state) => state.general.nodeId);
  const nodeId = externalNodeId || commonNodeId;

  return (
    <KeyValueGeneric keyText={model.label} helpText={helpText}>
      <SignalTypePickerMulti
        disabled={disabled}
        hasError={hasError}
        value={rawValue}
        onChange={updateItem}
        selectFolder={model.selectFolder}
        selectOnlyFolders={model.selectFolder}
        nodeId={nodeId}
        isClearable={model.isClearable}
      />
    </KeyValueGeneric>
  );
};

export default React.memo(ModelEditorSignalTypeList);
