import React, { useCallback } from 'react';
import _ from 'lodash';
import SignalTypePickerMulti from 'ecto-common/lib/SignalTypePicker/SignalTypePickerMulti';

interface SignalTypePickerProps {
  onChange?(signalTypeId: string): void;
  value?: string;
  hasError?: boolean;
  disabled?: boolean;
  selectFolder?: boolean;
  selectOnlyFolders?: boolean;
  showIcon?: boolean;
  nodeId?: string;
  isClearable?: boolean;
}

const SignalTypePicker = ({
  onChange,
  value,
  hasError,
  disabled,
  selectFolder,
  selectOnlyFolders,
  nodeId = null,
  showIcon = true,
  isClearable = false
}: SignalTypePickerProps) => {
  const onChangeSingle = useCallback(
    (signalTypeIds: string[]) => {
      if (signalTypeIds.length > 0) {
        onChange(signalTypeIds[0]);
      } else {
        onChange(null);
      }
    },
    [onChange]
  );

  return (
    <SignalTypePickerMulti
      onChange={onChangeSingle}
      value={value ? [value] : []}
      hasError={hasError}
      disabled={disabled}
      selectFolder={selectFolder}
      selectOnlyFolders={selectOnlyFolders}
      showIcon={showIcon}
      nodeId={nodeId}
      isClearable={isClearable}
      multiSelect={false}
    />
  );
};

export default React.memo(SignalTypePicker);
