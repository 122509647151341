import React from 'react';
import classNames from 'classnames';
import styles from './Button.module.css';

/**
 * Buttons enables users to make choices and perform actions.
 */
export type ButtonLikeProps = {
  /**
   * Use this when rendering icon-only Buttons (no text)
   */
  isIconButton?: boolean;
  /**
   * Makes the button slightly smaller, useful in tight layout constraints.
   */
  compact?: boolean;
  /**
   * Animates the background color, indicating that we are waiting for the result of an asynchronous process.
   */
  loading?: boolean;
  /**
   * If set, the button will expand to take all available width from parent container
   */
  useFullWidth?: boolean;
  /**
   * Use this to add a border to an icon button (off by default). Useful when using outside of list contexts.
   */
  hasIconButtonBorder?: boolean;
  children?: React.ReactNode;
};

export type ButtonProps = Omit<React.ComponentProps<'button'>, 'ref'> &
  ButtonLikeProps;

export const getButtonCSSStyles = (
  loading: boolean,
  disabled: boolean,
  isIconButton: boolean,
  hasIconButtonBorder: boolean,
  compact: boolean,
  useFullWidth: boolean,
  className?: string
) => {
  return classNames(
    styles.button,
    loading && styles.loading,
    disabled && styles.disabled,
    isIconButton && styles.iconButton,
    isIconButton && hasIconButtonBorder && styles.iconButtonBorder,
    compact && styles.compact,
    useFullWidth && styles.useFullWidth,
    className
  );
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      isIconButton = false,
      disabled = false,
      compact = false,
      loading = false,
      useFullWidth = false,
      hasIconButtonBorder = false,
      ...otherProps
    }: ButtonProps,
    ref
  ) => {
    const cssStyles = getButtonCSSStyles(
      loading,
      disabled,
      isIconButton,
      hasIconButtonBorder,
      compact,
      useFullWidth,
      className
    );

    return (
      <button
        type="button"
        disabled={disabled}
        className={cssStyles}
        ref={ref}
        {...otherProps}
      >
        {children}
      </button>
    );
  }
);

export default Button;
