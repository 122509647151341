import React, { MouseEvent } from 'react';
import classNames from 'classnames';
import styles from './Toast.module.css';
import { RemoveIcon } from '../Icon';
import { ERROR, SUCCESS, WARNING } from 'ecto-common/lib/Toast/toastTypes';
import Icons from 'ecto-common/lib/Icons/Icons';

type ToastProps = React.ComponentProps<'div'> & {
  /**
   * The content of the toast
   */
  children?: React.ReactNode;
  /**
   * Used to override the appearance of the toast. Should be a valid CSS class name.
   */
  className?: string;
  /**
   * The icon that is shown in the toast.
   */
  icon?: React.ReactNode;

  /**
   * Called whenever the user clicks the toast to remove it.
   */
  onRemove?: (event: MouseEvent<HTMLDivElement>) => void;

  /**
   * Whether the toast is supposed to be rendered as a minimal size control.
   * Suitable for scenarios where the toast is shown close to where the action was triggered.
   */
  isInline?: boolean;

  /**
   * The type of toast to show. Affects icons and colors.
   */
  type?: string;
};

/**
 * Component used to show notifications to the user. This class should not be used directly, you should use the toast store API instead.
 */
const Toast = React.forwardRef<HTMLDivElement, ToastProps>(
  (
    {
      children = null,
      onRemove = null,
      type = null,
      isInline = false,
      ...otherProps
    }: ToastProps,
    ref
  ) => {
    let className;
    let icon;

    switch (type) {
      case SUCCESS:
        className = styles.successToast;
        icon = <Icons.Checkmark />;
        break;
      case ERROR:
        className = styles.errorToast;
        icon = isInline ? <Icons.Error /> : <Icons.ToastAlert />;
        break;
      case WARNING:
        className = styles.warningToast;
        icon = isInline ? <Icons.Error /> : <Icons.ToastAlert />;
        break;
      default:
        throw new Error(`Unknown toast type '${type}'`);
    }

    return (
      <div
        ref={ref}
        className={classNames(
          styles.toast,
          className,
          isInline && styles.inlineToast
        )}
        {...otherProps}
      >
        <div className={styles.icon}>{icon}</div>
        <div className={styles.children}>{children}</div>
        <div className={styles.closeButton} onClick={onRemove}>
          <RemoveIcon />
        </div>
      </div>
    );
  }
);

export default React.memo(Toast);
