import APIGen from 'ecto-common/lib/API/APIGen';
import AddButton from 'ecto-common/lib/Button/AddButton';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import DataTableFooter from 'ecto-common/lib/DataTable/DataTableFooter';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import {
  ProcessMapRuleTypeOptions,
  ProcessMapSignalRuleFunction,
  ProcessMapSignalRuleFunctions,
  SignalDialogRule
} from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import {
  getSignalTypeNameWithUnitFromMap,
  getSignalTypeUnitSuffixFromSignalType
} from 'ecto-common/lib/SignalSelector/SignalUtils';
import TextInput from 'ecto-common/lib/TextInput/TextInput';
import { standardColumns } from 'ecto-common/lib/utils/dataTableUtils';
import { produce } from 'immer';
import _ from 'lodash';
import React, { useMemo } from 'react';

const functionOptions: GenericSelectOption<ProcessMapSignalRuleFunction>[] = [
  {
    value: ProcessMapSignalRuleFunctions.Visible,
    label: T.admin.processmaps.objecteditor.signalfunctions.visible
  },
  {
    value: ProcessMapSignalRuleFunctions.Enabled,
    label: T.admin.processmaps.objecteditor.signalfunctions.enabled
  }
];

const ProcessMapSignalRuleDialog = ({
  onModalClose,
  isOpen,
  signalRules,
  updateItem,
  signalIds,
  signalTypeIds
}: {
  onModalClose: () => void;
  isOpen: boolean;
  signalRules: SignalDialogRule[];
  updateItem: (item: SignalDialogRule[]) => void;
  signalIds: string[];
  signalTypeIds: string[];
}) => {
  const signalTypesMap = useCommonSelector(
    (state) => state.general.signalTypesMap
  );
  const signalUnitTypesMap = useCommonSelector(
    (state) => state.general.signalUnitTypesMap
  );

  const signalQuery = APIGen.Signals.getProvidersBySignalIds.useQuery(
    {
      signalIds
    },
    {
      enabled: isOpen && signalIds?.length > 0
    }
  );

  const signalTypeOptions = useMemo(() => {
    return signalTypeIds.map((signalTypeId) => ({
      value: signalTypeId,
      label: getSignalTypeNameWithUnitFromMap(
        signalTypeId,
        signalTypesMap,
        signalUnitTypesMap
      )
    }));
  }, [signalTypeIds, signalTypesMap, signalUnitTypesMap]);

  const signalOptions = useMemo(() => {
    if (signalQuery.data == null) {
      return [];
    }

    const allSignals = _.flatMap(signalQuery.data, (provider) =>
      provider.signals.filter((signal) => signalIds.includes(signal.signalId))
    );

    return allSignals.map((signal) => ({
      value: signal.signalId,
      label:
        signal.name +
        getSignalTypeUnitSuffixFromSignalType(
          signal.signalTypeId,
          signalTypesMap,
          signalUnitTypesMap
        )
    }));
  }, [signalIds, signalQuery.data, signalTypesMap, signalUnitTypesMap]);

  const combinedSignalOptions = useMemo(() => {
    return [...signalOptions, ...signalTypeOptions];
  }, [signalOptions, signalTypeOptions]);

  const columns: DataTableColumnProps<SignalDialogRule>[] = useMemo(() => {
    return [
      {
        dataKey: 'signalId',
        label: T.admin.processmaps.objecteditor.signalid.column,
        dataFormatter: (
          signalId: string,
          object: SignalDialogRule,
          rowIndex
        ) => {
          const selectedOption = combinedSignalOptions.find(
            (option) =>
              option.value === signalId || option.value === object.signalTypeId
          );

          return (
            <Select
              isLoading={signalQuery.isLoading}
              options={combinedSignalOptions}
              value={selectedOption}
              onChange={(newVal) => {
                updateItem(
                  produce(signalRules, (draft) => {
                    if (signalTypeIds.includes(newVal.value)) {
                      draft[rowIndex].signalTypeId = newVal.value;
                      draft[rowIndex].signalId = null;
                    } else {
                      draft[rowIndex].signalTypeId = null;
                      draft[rowIndex].signalId = newVal.value;
                    }
                  })
                );
              }}
            />
          );
        }
      },
      {
        dataKey: 'function',
        label: T.admin.processmaps.objecteditor.signalfunctions.column,
        dataFormatter: (value: string, _object, rowIndex) => {
          const selectedOption = functionOptions.find(
            (option) => option.value === value
          );
          return (
            <Select
              options={functionOptions}
              value={selectedOption}
              onChange={(newVal) => {
                updateItem(
                  produce(signalRules, (draft) => {
                    draft[rowIndex].function = newVal.value;
                  })
                );
              }}
            />
          );
        }
      },
      {
        dataKey: 'compareSignalId',
        label: T.admin.processmaps.objecteditor.comparesignalid.column,
        dataFormatter: (value: string, object: SignalDialogRule, rowIndex) => {
          const selectedOption = combinedSignalOptions.find(
            (option) =>
              option.value === value ||
              option.value === object.compareSignalTypeId
          );

          return (
            <Select
              isLoading={signalQuery.isLoading}
              options={combinedSignalOptions}
              value={selectedOption}
              onChange={(newVal) => {
                updateItem(
                  produce(signalRules, (draft) => {
                    if (signalTypeIds.includes(newVal.value)) {
                      draft[rowIndex].compareSignalTypeId = newVal.value;
                      draft[rowIndex].compareSignalId = null;
                    } else {
                      draft[rowIndex].compareSignalTypeId = null;
                      draft[rowIndex].compareSignalId = newVal.value;
                    }
                  })
                );
              }}
            />
          );
        }
      },
      {
        dataKey: 'operator',
        flexGrow: 0,
        minWidth: 100,
        dataFormatter: (value: string, _object, rowIndex) => {
          return (
            <Select
              options={ProcessMapRuleTypeOptions}
              value={ProcessMapRuleTypeOptions.find(
                (option) => option.value === value
              )}
              onChange={(newVal) => {
                updateItem(
                  produce(signalRules, (draft) => {
                    draft[rowIndex].operator = newVal.value;
                  })
                );
              }}
            />
          );
        }
      },
      {
        dataKey: 'expectedValue',
        flexGrow: 0,
        minWidth: 70,
        dataFormatter: (value: number, _object, rowIndex) => {
          return (
            <TextInput
              type="number"
              value={value}
              onChange={(event) => {
                const newNum = _.parseInt(event.target.value, 10);
                if (!isNaN(newNum)) {
                  updateItem(
                    produce(signalRules, (draft) => {
                      draft[rowIndex].expectedValue = newNum;
                    })
                  );
                }
              }}
            />
          );
        }
      },
      ...standardColumns({
        onDelete: (_item, index) => {
          updateItem(_.filter(signalRules, (_rule, i) => i !== index));
        }
      })
    ];
  }, [
    combinedSignalOptions,
    signalQuery.isLoading,
    signalRules,
    signalTypeIds,
    updateItem
  ]);

  return (
    <ActionModal
      isOpen={isOpen}
      onModalClose={onModalClose}
      onConfirmClick={onModalClose}
      title={T.admin.processmaps.objecteditor.signalruledialog.title}
      wide
      headerIcon={Icons.Edit}
      disableCancel
      actionText={T.common.done}
    >
      <DataTable columns={columns} data={signalRules} />

      <DataTableFooter alignRight>
        <AddButton
          onClick={() => {
            updateItem([
              ...(signalRules ?? []),
              {
                signalId: null,
                signalTypeId: null,
                compareSignalId: null,
                compareSignalTypeId: null,
                operator: '=',
                expectedValue: 1,
                function: 'visible'
              }
            ]);
          }}
        >
          {T.common.add}
        </AddButton>
      </DataTableFooter>
    </ActionModal>
  );
};

export default React.memo(ProcessMapSignalRuleDialog);
