import React from 'react';
import Button, { ButtonProps } from 'ecto-common/lib/Button/Button';
import classNames from 'classnames';
import styles from './Button.module.css';

type BorderlessButtonProps = ButtonProps & {
  destructive?: boolean;
};

const BorderlessButton = React.forwardRef<
  HTMLButtonElement,
  BorderlessButtonProps
>(
  (
    { className, destructive = false, ...otherProps }: BorderlessButtonProps,
    ref
  ) => {
    return (
      <Button
        ref={ref}
        className={classNames(
          styles.borderlessButton,
          destructive && styles.destructive,
          className
        )}
        {...otherProps}
      />
    );
  }
);

export default BorderlessButton;
