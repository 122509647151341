import React, { UIEventHandler } from 'react';
import classNames from 'classnames';
import styles from './ModalBody.module.css';
import Spinner, { SpinnerSize } from 'ecto-common/lib/Spinner/Spinner';

type ModalBodyProps = {
  children: React.ReactNode;
  loading?: boolean;
  loadingText?: React.ReactNode;
  className?: string;
  withoutPadding?: boolean;
  withWhiteBackground?: boolean;
  onScroll?: UIEventHandler<HTMLDivElement>;
};

/**
 *  This component should be used to wrap the modal inner content.
 */
const ModalBody = React.forwardRef<HTMLDivElement, ModalBodyProps>(
  (
    {
      children,
      loading = false,
      loadingText = null,
      className = null,
      withoutPadding = false,
      withWhiteBackground = false,
      onScroll = null,
      ...otherProps
    }: ModalBodyProps,
    ref
  ) => (
    <div
      className={classNames(
        styles.modalBody,
        loading && styles.loading,
        withoutPadding && styles.withoutPadding,
        withWhiteBackground && styles.withWhiteColor,
        className
      )}
      data-parent-scroller="true"
      onScroll={onScroll}
      ref={ref}
      {...otherProps}
    >
      {loading && (
        <div className={styles.modalLoadingOverlay}>
          <Spinner
            className={styles.spinner}
            color="white"
            size={SpinnerSize.MEDIUM}
          />
          {loadingText && <div>{loadingText}</div>}
        </div>
      )}
      <div
        className={classNames(styles.children, loading && styles.disableInput)}
      >
        {children}
      </div>
    </div>
  )
);

export default ModalBody;
